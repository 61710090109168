import React from 'react';

const HelpPage: React.FC = () => {
  return (
    <div className="help-page">
      <h1>Help & Support</h1>
      <p>Need assistance? Here are some frequently asked questions:</p>
      <ul>
        <li>How does lip-syncing work?</li>
        <li>What file formats are supported?</li>
        <li>How long does processing take?</li>
      </ul>
    </div>
  );
};

export default HelpPage;

import React from 'react';
import './ProductsPage.css';

interface Product {
  id: number;
  name: string;
  description: string;
  features: string[];
  image: string;
}

const products: Product[] = [
  {
    id: 1,
    name: "AI Video Creator",
    description: "Create stunning videos with the power of AI",
    features: [
      "Automated video generation",
      "Custom style templates",
      "Voice-to-text synchronization",
      "4K resolution support"
    ],
    image: "/api/placeholder/400/300"
  },
  {
    id: 2,
    name: "Smart Editor Pro",
    description: "Advanced editing tools for professionals",
    features: [
      "AI-powered scene detection",
      "Automated color grading",
      "Smart object removal",
      "Multi-track editing"
    ],
    image: "/api/placeholder/400/300"
  },
  {
    id: 3,
    name: "Content Amplifier",
    description: "Maximize your content's reach and engagement",
    features: [
      "AI-driven content recommendations",
      "Automated social media posting",
      "Engagement analytics",
      "A/B testing for thumbnails"
    ],
    image: "/api/placeholder/400/300"
  }
];

const ProductsPage: React.FC = () => {
  return (
    <div className="products-page">
      <section className="hero">
        <div className="container">
          <h1>Our Products</h1>
          <p>Discover our suite of AI-powered video creation and editing tools</p>
        </div>
      </section>

      <section className="products">
        <div className="container">
          {products.map((product) => (
            <div key={product.id} className="product-card">
              <div className="product-image">
                <img src={product.image} alt={product.name} />
              </div>
              <div className="product-info">
                <h2>{product.name}</h2>
                <p>{product.description}</p>
                <ul className="feature-list">
                  {product.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
                <button className="btn btn-primary">Learn More</button>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="cta">
        <div className="container">
          <h2>Ready to revolutionize your video content?</h2>
          <p>Start creating amazing videos with our AI-powered tools today!</p>
          <button className="btn btn-large">Get Started</button>
        </div>
      </section>
    </div>
  );
};

export default ProductsPage;

import { initializeApp } from 'firebase/app';
import { initializeFirestore, connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyCLoVSHrYYsi5zF5FOfVa-kWrvxshQwak0",
  authDomain: "webapp-12073.firebaseapp.com",
  projectId: "webapp-12073",
  storageBucket: "webapp-12073.appspot.com",
  messagingSenderId: "64031398239",
  appId: "1:64031398239:web:06833f4effe8bf02af7eea",
  measurementId: "G-5KR5FD79JT",
  databaseURL: "https://webapp-12073.firebaseio.com"
};

const app = initializeApp(firebaseConfig);

// Specify the database name when initializing Firestore
const DATABASE_ID = 'webapp';
const db = getFirestore(app, DATABASE_ID);

const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app);

// If you're using the emulator, uncomment these lines:
// connectFirestoreEmulator(db, 'localhost', 8080);
// connectFunctionsEmulator(functions, 'localhost', 5001);

const PROJECT_ID = 'webapp-12073';

export { app, db, auth, storage, DATABASE_ID, PROJECT_ID, functions };

import React, { useEffect, useState, useCallback } from 'react';
import { collection, query, getDocs, Timestamp } from "firebase/firestore";
import { db } from '../firebaseConfig';
import { Loader, AlertTriangle, Video } from 'lucide-react';
import { User as FirebaseUser } from 'firebase/auth';
import { getFunctions, httpsCallable } from "firebase/functions";
import './Dashboard.css';

interface User extends FirebaseUser {
  id: string;
  name: string | null;
  email: string | null;
}

interface Subscription {
  isSubscribed: boolean;
  plan: string;
  credits: number;
  tier: string;
  usageCount: number;
  limit: number;
}

interface Video {
  id: string;
  videoUrl: string;
  audioFileName: string;
  createdAt: Date | null;
}

interface DashboardProps {
  user: User | null;
  subscription: Subscription | null;
  subscribe: (priceId: string) => Promise<void>;
  cancelSubscription: () => Promise<void>;
}

const Dashboard: React.FC<DashboardProps> = ({ user, subscription, subscribe, cancelSubscription }) => {
  const [videos, setVideos] = useState<Video[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const fetchVideos = useCallback(async () => {
    if (!user) {
      setLoading(false);
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const videosRef = collection(db, "userVideos", user.id, "videos");
      const q = query(videosRef);
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        setVideos([]);
      } else {
        const fetchedVideos: Video[] = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          let createdAt: Date | null = null;
          if (data.createdAt instanceof Timestamp) {
            createdAt = data.createdAt.toDate();
          } else if (data.createdAt) {
            console.warn(`Unexpected createdAt format for video ${doc.id}:`, data.createdAt);
          }
          fetchedVideos.push({
            id: doc.id,
            videoUrl: data.videoUrl || '',
            audioFileName: data.audioFileName || 'Unnamed Audio',
            createdAt: createdAt,
          });
        });
        setVideos(fetchedVideos);
      }
    } catch (error) {
      console.error("Error fetching videos:", error);
      if (error instanceof Error) {
        setError(`Failed to load videos: ${error.message}`);
      } else {
        setError('An unknown error occurred while fetching videos');
      }
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchVideos();
    }
  }, [user, fetchVideos]);

  const handleSubscribe = async (priceId: string) => {
    if (!user) return;
    setIsRedirecting(true);
    try {
      const functions = getFunctions();
      const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
      const result = await createCheckoutSession({ priceId });
      const { sessionId, url } = result.data as { sessionId: string; url: string };

      if (url) {
        window.location.href = url;
      } else {
        throw new Error('No checkout URL returned from the server');
      }
    } catch (error) {
      console.error('Subscription failed:', error);
      if (error instanceof Error) {
        setError(`Failed to start subscription process: ${error.message}`);
      } else {
        setError('Failed to start subscription process. Please try again.');
      }
      setIsRedirecting(false);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      await cancelSubscription();
      // Optionally, you can update the local state or refetch the subscription status
    } catch (error) {
      console.error('Error canceling subscription:', error);
      setError('Failed to cancel subscription. Please try again.');
    }
  };

  const availablePlans = [
    { id: 'free', name: 'Starter', price: 0, period: 'Free', credits: 3, description: 'The ideal plan to get you started on your video creation journey.', features: ['5 credits/month', 'Basic video quality', 'Email support'] },
    { id: 'price_1Q2TRzDVQOYCdkg6dBVj2lya', name: 'Pro', price: 19.99, period: 'Monthly', credits: 150, description: 'Perfect for creators looking to expand their video capabilities with advanced tools.', features: ['150 credits/month', '4K video quality', 'Priority support', 'Advanced editing tools'] },
    { id: 'price_1Q2TTLDVQOYCdkg6tM2GAzPl', name: 'Enterprise', price: 49.99, period: 'Starting', credits: '500', description: 'The ultimate plan for businesses seeking comprehensive video control and growth.', features: ['500 credits/month', '4K video quality', 'Dedicated account manager', 'Custom integrations'] },
  ];

  if (!user) {
    return (
      <div className="db-dashboard-container">
        <section className="db-hero">
          <div className="db-container">
            <h1>Dashboard</h1>
            <p>Please sign in to view your dashboard.</p>
          </div>
        </section>
      </div>
    );
  }

  return (
    <div className="db-dashboard-container">
      <section className="db-hero">
        <div className="db-container">
          <h1>Dashboard</h1>
          <p>Welcome, {user.name || 'User'}</p>
        </div>
      </section>

      {subscription && (
        <section className="db-subscription-details">
          <div className="db-container">
            <h2>Subscription Details</h2>
            <div className="db-card">
              <p>Current Plan: <strong>{subscription.plan}</strong></p>
              <p>Credits: {subscription.credits}</p>
              <p>Usage: {subscription.usageCount} / {subscription.limit}</p>
              <div className="db-button-group db-mt-4">
                {subscription.plan.toLowerCase() === 'free' ? (
                  <a 
                    href="#upgrade-plan" 
                    className="db-btn db-btn-primary"
                  >
                    Upgrade Plan
                  </a>
                ) : (
                  <>
                    <a 
                      href="#upgrade-plan" 
                      className="db-btn db-btn-primary"
                    >
                      Change Plan
                    </a>
                    <button 
                      onClick={handleCancelSubscription}
                      disabled={isRedirecting}
                      className="db-btn db-btn-secondary"
                    >
                      Cancel Subscription
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      )}

      <section id="upgrade-plan" className="db-pricing-tiers db-upgrade-plan">
        <div className="db-container">
          <h2>Upgrade Your Plan</h2>
          <div className="db-container">
            {availablePlans.map((plan) => (
              <div key={plan.id} className={`db-pricing-card ${plan.name === 'Pro' ? 'db-recommended' : ''} ${plan.id === subscription?.plan ? 'db-current-plan' : ''}`}>
                {plan.name === 'Pro' && <div className="db-recommended-badge">Recommended</div>}
                {plan.id === subscription?.plan && <div className="db-current-plan-badge">Current Plan</div>}
                <h2>{plan.name}</h2>
                <div className="db-price">
                  {plan.price === 0 ? (
                    <span className="db-free">{plan.period}</span>
                  ) : (
                    <>
                      <span className="db-currency">$</span>
                      <span className="db-amount">{plan.price.toFixed(2)}</span>
                      <span className="db-period">{plan.period}</span>
                    </>
                  )}
                </div>
                <p className="db-description">{plan.description}</p>
                {subscription?.plan !== plan.id && plan.id !== 'free' && (
                  <button 
                    onClick={() => handleSubscribe(plan.id)}
                    className="db-btn db-btn-primary"
                    disabled={isRedirecting}
                  >
                    {isRedirecting ? 'Redirecting...' : subscription?.plan === 'free' ? 'Upgrade' : 'Switch'}
                  </button>
                )}
                {subscription?.plan === plan.id && plan.id !== 'free' && (
                  <button 
                    onClick={handleCancelSubscription}
                    className="db-btn db-btn-secondary"
                    disabled={isRedirecting}
                  >
                    Cancel Subscription
                  </button>
                )}
                <div className="db-features">
                  <h3>Includes</h3>
                  <ul>
                    {plan.features.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {error && <div className="db-error-message db-container">{error}</div>}

      <section className="db-your-videos">
        <div className="db-container">
          <h2>Your Videos</h2>
          {loading ? (
            <div className="db-loading">
              <Loader className="db-animate-spin" />
              <span>Loading videos...</span>
            </div>
          ) : error ? (
            <div className="db-error-message">
              <AlertTriangle />
              <span>{error}</span>
            </div>
          ) : videos.length > 0 ? (
            <div className="db-video-grid">
              {videos.map((video) => (
                <div key={video.id} className="db-video-item">
                  <video controls src={video.videoUrl} />
                  <div className="db-video-info">
                    <p>{video.audioFileName}</p>
                    <p>Created: {video.createdAt?.toLocaleString()}</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="db-no-videos">
              <Video />
              <p>You haven't created any videos yet.</p>
              <p>Go to the Video Creator to get started!</p>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Dashboard;

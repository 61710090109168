import { createContext, useContext, useState } from 'react';
import { Timestamp } from 'firebase/firestore';

interface Video {
  id: string;
  videoUrl: string;
  audioFileName: string;
  createdAt: Timestamp;
}

interface VideoContextProps {
  videos: Video[];
  setVideos: React.Dispatch<React.SetStateAction<Video[]>>;
}

const VideoContext = createContext<VideoContextProps>({
  videos: [],
  setVideos: () => {},
});

export const useVideoContext = () => useContext(VideoContext);

export const VideoProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [videos, setVideos] = useState<Video[]>([]);

  return (
    <VideoContext.Provider value={{ videos, setVideos }}>
      {children}
    </VideoContext.Provider>
  );
};
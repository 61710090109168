import React, { useState } from 'react';
import './ContactPage.css';
import { FaDiscord } from 'react-icons/fa';

const ContactPage: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log({ name, email, phone, message });
  };

  return (
    <div className="cp-contact-page">
      <div className="cp-container">
        <h1 className="cp-title">Contact us</h1>
        <p className="cp-subtitle">
          Reach out to us with any questions, feedback, or inquiries. Our dedicated team is here to assist you.
        </p>

        <div className="cp-content">
          <div className="cp-info">
            <div className="cp-info-section">
              <h2>CONTACT</h2>
              <p>hello@adcipher.io</p>
              <p>+555-123-4567</p>
            </div>
            <div className="cp-info-section">
              <h2>ADDRESS</h2>
              <p>1234 Elm Street</p>
              <p>Springfield, Anytown</p>
              <p>54321 Fictionville, USA</p>
            </div>
            <div className="cp-info-section">
              <h2>DISCORD</h2>
              <p>Join our discord community for the latest updates and live access to our support</p>
              <div className="cp-discord">
                <FaDiscord className="cp-discord-icon" />
                <a href="https://discord.gg/your-discord-link" target="_blank" rel="noopener noreferrer">
                  Click here to join
                </a>
              </div>
            </div>
          </div>

          <form className="cp-form" onSubmit={handleSubmit}>
            <div className="cp-form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Full Name"
                required
              />
            </div>
            <div className="cp-form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="info@yourcompany.com"
                required
              />
            </div>
            <div className="cp-form-group">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="(555) 444-3333"
              />
            </div>
            <div className="cp-form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="your message"
                required
              />
            </div>
            <button type="submit" className="cp-submit-button">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;

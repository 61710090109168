import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './SubscriptionUpgrade.css';

const stripePromise = loadStripe('your_stripe_publishable_key');

interface PricingTier {
  id: string;
  name: string;
  monthlyPrice: number;
  yearlyPrice: number;
  features: string[];
  recommended?: boolean;
}

interface SubscriptionUpgradeProps {
  currentPlan: string;
  subscribe: (planId: string, paymentMethodId?: string) => Promise<void>;
  cancelSubscription: () => Promise<void>;
}

const pricingTiers: PricingTier[] = [
  {
    id: 'basic',
    name: 'Basic',
    monthlyPrice: 9.99,
    yearlyPrice: 99.99,
    features: [
      '5 AI-generated videos per month',
      'Basic editing tools',
      '720p video quality',
      'Email support'
    ]
  },
  {
    id: 'pro',
    name: 'Pro',
    monthlyPrice: 19.99,
    yearlyPrice: 199.99,
    features: [
      '20 AI-generated videos per month',
      'Advanced editing tools',
      '1080p video quality',
      'Priority email support',
      'Custom branding'
    ],
    recommended: true
  },
  {
    id: 'enterprise',
    name: 'Enterprise',
    monthlyPrice: 49.99,
    yearlyPrice: 499.99,
    features: [
      'Unlimited AI-generated videos',
      'Premium editing suite',
      '4K video quality',
      '24/7 priority support',
      'Custom integrations',
      'Dedicated account manager'
    ]
  }
];

const CardForm: React.FC<{ onSubmit: (paymentMethodId: string) => Promise<void>, isProcessing: boolean }> = ({ onSubmit, isProcessing }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setError('Card element not found');
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setError(error.message || 'An error occurred');
    } else if (paymentMethod) {
      await onSubmit(paymentMethod.id);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="card-form">
      <CardElement />
      <button type="submit" disabled={!stripe || isProcessing} className="submit-button">
        {isProcessing ? 'Processing...' : 'Submit Payment'}
      </button>
      {error && <div className="error-message">{error}</div>}
    </form>
  );
};

const SubscriptionUpgrade: React.FC<SubscriptionUpgradeProps> = ({ currentPlan, subscribe, cancelSubscription }) => {
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showCardForm, setShowCardForm] = useState(false);
  const [annualBilling, setAnnualBilling] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Current Plan in SubscriptionUpgrade:', currentPlan);
  }, [currentPlan]);

  const handleUpgrade = async (paymentMethodId: string) => {
    if (selectedPlan && selectedPlan !== currentPlan) {
      setIsProcessing(true);
      setError(null);
      try {
        await subscribe(selectedPlan, paymentMethodId);
        console.log(`Successfully upgraded to ${selectedPlan}`);
        navigate('/dashboard');
      } catch (err) {
        setError('Failed to upgrade. Please try again.');
        console.error(err);
      } finally {
        setIsProcessing(false);
      }
    }
  };

  const handleCancel = async () => {
    setIsProcessing(true);
    setError(null);
    try {
      await cancelSubscription();
      console.log('Successfully cancelled subscription');
      navigate('/dashboard');
    } catch (err) {
      setError('Failed to cancel subscription. Please try again.');
      console.error(err);
    } finally {
      setIsProcessing(false);
    }
  };

  const canUpgrade = (planId: string) => {
    const currentPlanIndex = pricingTiers.findIndex(p => p.id === currentPlan);
    const newPlanIndex = pricingTiers.findIndex(p => p.id === planId);
    return newPlanIndex > currentPlanIndex;
  };

  return (
    <div className="pricing-page subscription-upgrade-container">
      <section className="hero">
        <div className="container">
          <h1>Manage Your Subscription</h1>
          <p>Your current plan: {pricingTiers.find(p => p.id === currentPlan)?.name || `Unknown Plan (${currentPlan})`}</p>
        </div>
      </section>

      <section className="pricing-toggle">
        <div className="container">
          <span className={!annualBilling ? 'active' : ''}>Monthly Billing</span>
          <label className="switch">
            <input
              type="checkbox"
              checked={annualBilling}
              onChange={() => setAnnualBilling(!annualBilling)}
            />
            <span className="slider round"></span>
          </label>
          <span className={annualBilling ? 'active' : ''}>Annual Billing</span>
        </div>
      </section>

      <section className="pricing-tiers">
        <div className="container">
          {pricingTiers.map((tier) => (
            <div key={tier.id} className={`pricing-card ${tier.recommended ? 'recommended' : ''} ${tier.id === currentPlan ? 'current-plan' : ''} ${tier.id === selectedPlan ? 'selected-plan' : ''}`}>
              {tier.recommended && <div className="recommended-badge">Recommended</div>}
              {tier.id === currentPlan && <div className="current-plan-badge">Current Plan</div>}
              <h2>{tier.name}</h2>
              <div className="price">
                <span className="currency">$</span>
                <span className="amount">
                  {annualBilling
                    ? (tier.yearlyPrice / 12).toFixed(2)
                    : tier.monthlyPrice.toFixed(2)}
                </span>
                <span className="period">/ month</span>
              </div>
              {annualBilling && (
                <div className="yearly-price">
                  ${tier.yearlyPrice.toFixed(2)} billed annually
                </div>
              )}
              <ul className="features">
                {tier.features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
              {tier.id === currentPlan ? (
                <div className="current-plan-label">Your Current Plan</div>
              ) : canUpgrade(tier.id) ? (
                <button 
                  className={`btn btn-primary ${tier.id === selectedPlan ? 'selected' : ''}`}
                  onClick={() => setSelectedPlan(tier.id)}
                >
                  Select Plan
                </button>
              ) : null}
            </div>
          ))}
        </div>
      </section>

      <section className="subscription-actions">
        <div className="container">
          {selectedPlan && selectedPlan !== currentPlan && (
            <>
              {!showCardForm ? (
                <button 
                  className="btn btn-primary upgrade-button" 
                  onClick={() => setShowCardForm(true)}
                  disabled={isProcessing}
                >
                  Upgrade to {pricingTiers.find(p => p.id === selectedPlan)?.name}
                </button>
              ) : (
                <Elements stripe={stripePromise}>
                  <CardForm onSubmit={handleUpgrade} isProcessing={isProcessing} />
                </Elements>
              )}
            </>
          )}
          {currentPlan !== 'basic' && (
            <button 
              className="btn btn-secondary cancel-button" 
              onClick={handleCancel}
              disabled={isProcessing}
            >
              Cancel Subscription
            </button>
          )}
          {error && <p className="error-message">{error}</p>}
        </div>
      </section>

      <section className="faq">
        <div className="container">
          <h2>Frequently Asked Questions</h2>
          <div className="faq-grid">
            <div className="faq-item">
              <h3>Can I change my plan later?</h3>
              <p>Yes, you can upgrade or downgrade your plan at any time. Changes will be reflected in your next billing cycle.</p>
            </div>
            <div className="faq-item">
              <h3>Is there a free trial?</h3>
              <p>We offer a 14-day free trial for all plans. No credit card required.</p>
            </div>
            <div className="faq-item">
              <h3>What payment methods do you accept?</h3>
              <p>We accept all major credit cards and PayPal.</p>
            </div>
            <div className="faq-item">
              <h3>Is there a refund policy?</h3>
              <p>We offer a 30-day money-back guarantee for all plans.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SubscriptionUpgrade;

import React from 'react';
import './PricingPage.css';

interface PricingTier {
  id: string;
  name: string;
  price: number;
  period: string;
  description: string;
  features: string[];
  recommended?: boolean;
}

const pricingTiers: PricingTier[] = [
  {
    id: 'starter',
    name: 'Starter',
    price: 0,
    period: 'Free',
    description: 'The ideal plan to get you started on your ad creation journey.',
    features: [
      '5 credits per month',
      'Customer Analysis Tool',
      'Ad Copy Generator Tool',
      'Video Creator Tool',
    ]
  },
  {
    id: 'pro',
    name: 'Pro',
    price: 19.99,
    period: 'Monthly',
    description: 'Perfect for marketers looking to expand their ad creation capabilities with advanced ai tools.',
    features: [
      '150 credits per month',
      'Customer Analysis Tool',
      'Ad Copy Generator Tool',
      'Video Creator Tool',
      'Priority support',
      'Custom review scraper extension'
    ],
    recommended: true
  },
  {
    id: 'enterprise',
    name: 'Enterprise',
    price: 49.99,
    period: 'Monthly',
    description: 'The ultimate plan for marketers seeking comprehensive ad creation control and growth.',
    features: [
      '500 credits per month',
      'Customer Analysis Tool',
      'Ad Copy Generator Tool',
      'Video Creator Tool',
      'Priority support',
      'Custom review scraper extension',
      'Early access to new features'
    ]
  }
];

const faqItems = [
  {
    question: "What are credits used for?",
    answer: "Credits are used to generate AI-powered ad insights, ad copy, and custom AI videos."
  },
  {
    question: "Can I upgrade or downgrade my plan?",
    answer: "Yes, you can upgrade or downgrade your plan at any time. Changes will be reflected in your next billing cycle."
  },
  {
    question: "Is there a free trial for paid plans?",
    answer: "We offer a free starter plan with 5 credits per month. No credit card required to start."
  },
  {
    question: "What payment methods do you accept?",
    answer: "We accept all major credit cards for subscription payments."
  },
  {
    question: "Is there a refund policy?",
    answer: "We offer a 30-day money-back guarantee for all paid plans if you're not satisfied with our service."
  }
];

const PricingPage: React.FC = () => {
  return (
    <div className="pp-pricing-page">
      <section className="pp-hero">
        <div className="pp-container">
          <h1>Discover the perfect plan for you.</h1>
        </div>
      </section>

      <section className="pp-pricing-tiers">
        <div className="pp-container">
          {pricingTiers.map((tier) => (
            <div key={tier.id} className={`pp-pricing-card ${tier.recommended ? 'pp-recommended' : ''}`}>
              <h2>{tier.name}</h2>
              <div className="pp-price">
                {tier.price === 0 ? (
                  <span className="pp-free">{tier.period}</span>
                ) : (
                  <>
                    <span className="pp-currency">$</span>
                    <span className="pp-amount">{tier.price.toFixed(2)}</span>
                    <span className="pp-period">{tier.period}</span>
                  </>
                )}
              </div>
              <p className="pp-description">{tier.description}</p>
              <button className={`pp-btn ${tier.recommended ? 'pp-btn-primary' : 'pp-btn-secondary'}`}>
                Get Started
              </button>
              <div className="pp-features">
                <h3>Includes</h3>
                <ul>
                  {tier.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
        <div className="pp-container">
          <p className="pp-enterprise-cta">
            Need more credits? Get in contact with us today to learn about our custom plans.
          </p>
        </div>
      </section>

      <section className="pp-faq">
        <div className="pp-container">
          <h2>Frequently Asked Questions</h2>
          <div className="pp-faq-grid">
            {faqItems.map((item, index) => (
              <div key={index} className="pp-faq-item">
                <h3>{item.question}</h3>
                <p>{item.answer}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default PricingPage;

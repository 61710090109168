import React from 'react';
import { FaLinkedin, FaTwitter, FaFacebook, FaYoutube, FaInstagram, FaTiktok } from 'react-icons/fa';
import logo from '../assets/logo.png'; // Add this import

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <div className="footer-header">
            <img src={logo} alt="AdCipher Logo" className="footer-logo" />
          </div>
          <p>Discover the secret code of viral ads with AI</p>
        </div>

        <div className="footer-section">
          <h3>Products</h3>
          <ul className="footer-links">
            <li><a href="/for-brands">For Brands</a></li>
            <li><a href="/for-creators">For Creators</a></li>
            <li><a href="/for-talent-managers">For Talent Managers</a></li>
            <li><a href="/for-brand-agencies">For Brand Agencies</a></li>
          </ul>
        </div>

        <div className="footer-section">
          <h3>Learn</h3>
          <ul className="footer-links">
            <li><a href="/interviews">Interviews</a></li>
            <li><a href="/case-studies">Case Studies</a></li>
            <li><a href="/blog">Blog</a></li>
            <li><a href="/help">Help</a></li>
            <li><a href="/api">API</a></li>
            <li><a href="/status">Status</a></li>
          </ul>
        </div>

        <div className="footer-section">
          <h3>Company</h3>
          <ul className="footer-links">
            <li><a href="/pricing">Pricing</a></li>
            <li><a href="/story">Story</a></li>
            <li><a href="/investors">Investors</a></li>
            <li><a href="/team">Team</a></li>
            <li><a href="/careers">Careers</a></li>
            <li><a href="/terms">Terms</a></li>
            <li><a href="/privacy">Privacy</a></li>
          </ul>
        </div>

        <div className="footer-section">
          <h3>Contact</h3>
          <ul className="footer-links">
            <li><a href="mailto:sales@icon.me">sales@adcipher.io</a></li>
            <li><a href="mailto:help@icon.me">help@adcipher.io</a></li>
            <li><a href="mailto:careers@icon.me">careers@adcipher.io</a></li>
            <li><a href="mailto:press@icon.me">press@adcipher.io</a></li>
            <li><a href="mailto:kennan@icon.me">kennan@adcipher.io</a></li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <p>Copyright © 2024 AdCipher, Inc. All rights reserved.</p>
        <div className="social-icons">
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
          <a href="https://youtube.com" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
          <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer"><FaTiktok /></a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
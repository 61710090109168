import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";
import { getFunctions, httpsCallable } from 'firebase/functions';
import './App.css';
import Navigation from './components/Navigation';
import HomePage from './components/HomePage';
import PricingPage from './components/PricingPage';
import ProductsPage from './components/ProductsPage';
import ResourcesPage from './components/ResourcesPage';
import CompanyPage from './components/CompanyPage';
import VideoCreator from './components/VideoCreator';
import SubscriptionUpgrade from './components/SubscriptionUpgrade';
import Dashboard from './components/Dashboard';
import HelpPage from './components/HelpPage';
import ContactPage from './components/ContactPage'; // Add this import
import Features from './components/Features'; // Add this import
import NotFound from './components/NotFound'; 
import Footer from './components/Footer';
import AuthSubscription from './components/AuthSubscription';
import { VideoProvider } from './VideoContext';
import { useAuthSubscription } from './useAuthSubscription';
import { Subscription } from './types/SharedTypes';
import ErrorBoundary from './ErrorBoundary';
import CustomerAnalysis from './components/CustomerAnalysis'; // Add this import
import AdCopyGenerator from './components/AdCopyGenerator'; // Add this import
import favicon from './assets/fav.webp';

// Initialize GA4 with your measurement ID
ReactGA.initialize("G-5KR5FD79JT");

const AppContent: React.FC = () => {
  const { user, subscription, loading, signOut } = useAuthSubscription();
  const [error, setError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentPage, setCurrentPage] = useState<string>('home');
  const navigate = useNavigate();
  const functions = getFunctions();
  const location = useLocation();

  const deductCredit = async () => {
    console.log('Credit deducted');
  };

  const onUpgradeClick = () => {
    navigate('/upgrade');
  };

  const subscribe = async (planId: string, paymentMethodId?: string) => {
    setIsProcessing(true);
    setError(null);
    try {
      let priceId = planId === 'pro' ? 'price_1Q2TRzDVQOYCdkg6dBVj2lya' : 'price_1Q2TTLDVQOYCdkg6tM2GAzPl';
      const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
      const result = await createCheckoutSession({ priceId });
      const { sessionId } = result.data as { sessionId: string };
      window.location.href = `https://checkout.stripe.com/pay/${sessionId}`;
    } catch (err) {
      console.error('Subscription failed:', err);
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
    } finally {
      setIsProcessing(false);
    }
  };

  const cancelSubscription = async () => {
    setIsProcessing(true);
    setError(null);
    try {
      const cancelSubscriptionFunction = httpsCallable(functions, 'cancelSubscription');
      await cancelSubscriptionFunction();
    } catch (err) {
      setError('Failed to cancel subscription. Please try again.');
      console.error('Cancellation failed:', err);
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    console.log('Subscription in App:', subscription);
  }, [subscription]);

  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return (
    <AuthSubscription>
      {({ signIn }) => (
        <div className="app-wrapper">
          {loading ? (
            <div className="loading-spinner-container">
              <div className="loading-spinner"></div>
            </div>
          ) : (
            <>
              <Navigation
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                user={user}
                subscription={subscription}
                onSignOut={signOut}
                onSignIn={signIn}
              />
              <div className="scrollable-content">
                <main className="app-content">
                  {error && (
                    <div className="error">
                      <p>Error: {error}</p>
                    </div>
                  )}
                  {isProcessing && <div>Processing...</div>}
                  <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/pricing" element={<PricingPage />} />
                    <Route path="/products" element={<ProductsPage />} />
                    <Route path="/resources" element={<ResourcesPage />} />
                    <Route path="/company" element={<CompanyPage />} />
                    <Route path="/features" element={<Features />} />
                    <Route 
                      path="/create" 
                      element={
                        <VideoCreator 
                          user={user} 
                          subscription={subscription} 
                          deductCredit={deductCredit} 
                          onUpgradeClick={onUpgradeClick} 
                        />
                      } 
                    />
                    <Route path="/customer-analysis" element={<CustomerAnalysis />} />
                    <Route path="/ad-copy-generator" element={<AdCopyGenerator />} />
                    <Route 
                      path="/upgrade" 
                      element={
                        <SubscriptionUpgrade 
                          currentPlan={subscription?.plan || 'Free'} 
                          subscribe={subscribe} 
                          cancelSubscription={cancelSubscription} 
                        />
                      } 
                    />
                    <Route 
                      path="/dashboard" 
                      element={
                        <Dashboard 
                          user={user} 
                          subscription={subscription as Subscription | null} 
                          subscribe={subscribe}
                          cancelSubscription={cancelSubscription}
                        />
                      } 
                    />
                    <Route path="/help" element={<HelpPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </main>
                <Footer />
              </div>
            </>
          )}
        </div>
      )}
    </AuthSubscription>
  );
};

const App: React.FC = () => {
  useEffect(() => {
    // This ensures that the initial page load is tracked
    ReactGA.send("pageview");

    // Favicon setup
    const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (link) {
      link.href = favicon;
    } else {
      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      newLink.href = favicon;
      document.head.appendChild(newLink);
    }
  }, []);

  return (
    <ErrorBoundary>
      <VideoProvider>
        <Router>
          <AppContent />
        </Router>
      </VideoProvider>
    </ErrorBoundary>
  );
};

export default App;

import React from 'react';
import './CompanyPage.css';

const CompanyPage: React.FC = () => {
  return (
    <div className="company-page">
      <section className="hero">
        <div className="container">
          <h1>About AdCipher</h1>
          <p>An advanced AI-powered platform that helps marketers create more effective ads.</p>
        </div>
      </section>

      <section className="mission">
        <div className="container">
          <h2>Our Mission</h2>
          <p>To empower advertisers and marketers with cutting-edge AI tools, transforming the way video ads are analyzed, optimized, and delivered for maximum impact.</p>
        </div>
      </section>

      
      <section className="values">
        <div className="container">
          <h2>Our Values</h2>
          <div className="values-grid">
            <div className="value-item">
              <h3>Innovation</h3>
              <p>Pushing the boundaries of AI in video advertising technology.</p>
            </div>
            <div className="value-item">
              <h3>Data-Driven</h3>
              <p>Leveraging advanced analytics to deliver actionable insights.</p>
            </div>
            <div className="value-item">
              <h3>User-Centric</h3>
              <p>Designing intuitive tools that empower marketers of all skill levels.</p>
            </div>
            <div className="value-item">
              <h3>Integrity</h3>
              <p>Ensuring data privacy and maintaining ethical AI practices.</p>
            </div>
          </div>
        </div>
      </section>

      {/* 
      <section className="team">
        <div className="container">
          <h2>Our Team</h2>
          <div className="team-grid">
            <div className="team-member">
              <img src="/api/placeholder/150/150" alt="John Doe" />
              <h3>John Doe</h3>
              <p>CEO & Founder</p>
            </div>
            <div className="team-member">
              <img src="/api/placeholder/150/150" alt="Jane Smith" />
              <h3>Jane Smith</h3>
              <p>CTO</p>
            </div>
            <div className="team-member">
              <img src="/api/placeholder/150/150" alt="Mike Johnson" />
              <h3>Mike Johnson</h3>
              <p>Head of AI Research</p>
            </div>
            <div className="team-member">
              <img src="/api/placeholder/150/150" alt="Sarah Lee" />
              <h3>Sarah Lee</h3>
              <p>Head of Product</p>
            </div>
          </div>
        </div>
      </section>
      */}

      <section className="contact">
        <div className="container">
          <h2>Get in Touch</h2>
          <p>Ready to revolutionize your video advertising strategy? Let's discuss how AdCipher can boost your campaign performance!</p>
          <a href="mailto:contact@adcipher.com" className="cp-btn">Contact Us</a>
        </div>
      </section>
    </div>
  );
};

export default CompanyPage;

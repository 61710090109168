import React, { useCallback } from 'react';
import { auth } from '../firebaseConfig';
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";

interface AuthSubscriptionProps {
  children: (props: { signIn: () => Promise<void> }) => React.ReactNode;
}

const AuthSubscription: React.FC<AuthSubscriptionProps> = ({ children }) => {
  const signIn = useCallback(async () => {
    console.log("signIn function in AuthSubscription called");
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      console.log("Sign-in successful");
    } catch (error) {
      console.error("Error signing in:", error);
      throw error;
    }
  }, []);

  return <>{children({ signIn })}</>;
};

export default React.memo(AuthSubscription);

import { useEffect } from 'react';

const useProcessQueue = (
  generationQueue: number[],
  loading: boolean,
  deductCredit: () => Promise<void>,
  generateNextVideo: () => Promise<void>,
  setGlobalError: (msg: string) => void
) => {
  useEffect(() => {
    const processQueue = async () => {
      if (generationQueue.length > 0 && !loading) {
        try {
          await deductCredit(); // Deduct credit
          await generateNextVideo();
        } catch (error) {
          console.error('Error using credit or generating video:', error);
          setGlobalError('Failed to use credit or generate video. Please try again.');
        }
      }
    };

    processQueue();
  }, [generationQueue, loading, deductCredit, generateNextVideo, setGlobalError]);
};

export default useProcessQueue;

import React, { useCallback, useEffect, useState, CSSProperties } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navigation.css';
import { User as FirebaseUser } from 'firebase/auth';
import logo from '../assets/logo.png';
import { FaDiscord } from 'react-icons/fa';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

interface AppUser extends FirebaseUser {
  id: string;
  name: string;
}

interface Subscription {
  tier: string;
  usageCount: number;
  limit: number;
}

interface NavigationProps {
  currentPage: string;
  setCurrentPage: (page: string) => void;
  user: AppUser | null;
  subscription: Subscription | null;
  onSignIn: () => Promise<void>;
  onSignOut: () => Promise<void>;
}

const Navigation: React.FC<NavigationProps> = ({
  currentPage,
  setCurrentPage,
  user,
  subscription,
  onSignIn,
  onSignOut,
}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const scrollableContent = document.querySelector('.scrollable-content');
    
    const handleScroll = () => {
      if (scrollableContent) {
        const scrollPosition = scrollableContent.scrollTop;
        setIsScrolled(scrollPosition > 0);
      }
    };

    if (scrollableContent) {
      scrollableContent.addEventListener('scroll', handleScroll);
      handleScroll(); // Check initial scroll position
    }

    return () => {
      if (scrollableContent) {
        scrollableContent.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const navStyle: CSSProperties = {
    backgroundColor: isScrolled ? 'rgba(0, 0, 0, 0.8)' : 'transparent',
    transition: 'background-color 0.3s ease, top 0.3s ease-in-out',
    top: '0',
  };

  console.log('Rendering Navigation. isScrolled:', isScrolled);

  const handleNavClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>, page: string) => {
    e.preventDefault();
    setCurrentPage(page);
    navigate(page === 'home' ? '/' : `/${page}`);
  }, [setCurrentPage, navigate]);

  const handleSignIn = useCallback(async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    console.log("Sign-in button clicked in Navigation");
    try {
      await onSignIn();
      navigate('/');
    } catch (error) {
      console.error("Error in Navigation handleSignIn:", error);
    }
  }, [onSignIn, navigate]);

  const handleSignOut = useCallback(async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    console.log("Sign-out button clicked in Navigation");
    try {
      await onSignOut();
      navigate('/');
    } catch (error) {
      console.error("Error in Navigation handleSignOut:", error);
    }
  }, [onSignOut, navigate]);

  const navItems = [
    { name: 'Home', link: 'home' },
    { name: 'About', link: 'company' },
    { name: 'Features', link: 'features' },
    { name: 'Pricing', link: 'pricing' },
    { name: 'Contact', link: 'contact' },
  ];

  const appsMenu = (
    <Menu>
      <Menu.Item key="video-creator">
        <Link to="/create">Video Creator</Link>
      </Menu.Item>
      <Menu.Item key="customer-analysis">
        <Link to="/customer-analysis">Customer Analysis</Link>
      </Menu.Item>
      <Menu.Item key="ad-copy-generator">
        <Link to="/ad-copy-generator">Ad Copy Generator</Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {!isScrolled && (
        <div className="beta-banner">
          🚧 AdCipher is currently in beta. We're working hard to improve your experience, but you may encounter some bugs. Your feedback is invaluable in helping us refine the app. Thank you for being an early adopter!
        </div>
      )}
      <nav className={`navigation ${isScrolled ? 'scrolled' : ''}`}>
        <div className="nav-container">
          <Link to="/" onClick={(e) => handleNavClick(e, 'home')} className="nav-logo">
            <img src={logo} alt="AdCipher Logo" className="nav-logo-image" style={{ height: '25px' }} />
          </Link>
          <div className="nav-links">
            {navItems.map((item) => (
              <Link
                key={item.name}
                to={`/${item.link}`}
                onClick={(e) => handleNavClick(e, item.link)}
                className={`nav-link ${currentPage === item.link ? 'active' : ''}`}
              >
                {item.name}
              </Link>
            ))}
          </div>
          <div className="nav-actions">
            <a href="https://discord.gg/your-invite-link" target="_blank" rel="noopener noreferrer" className="nav-discord">
              <FaDiscord size={24} />
            </a>
            {user ? (
              <>
                <Link
                  to="/dashboard"
                  onClick={(e) => handleNavClick(e, 'dashboard')}
                  className={`nav-link ${currentPage === 'dashboard' ? 'active' : ''}`}
                >
                  Dashboard
                </Link>
                <Dropdown overlay={appsMenu} placement="bottomCenter">
                  <a className="nav-link" onClick={(e) => e.preventDefault()}>
                    Apps <DownOutlined />
                  </a>
                </Dropdown>
                <button onClick={handleSignOut} className="nav-button">
                  Log out
                </button>
              </>
            ) : (
              <>
                <button onClick={handleSignIn} className="nav-button">
                  Log in
                </button>
                <Link
                  to="/signup"
                  onClick={(e) => handleNavClick(e, 'signup')}
                  className="nav-button nav-button-primary"
                >
                  Sign up
                </Link>
              </>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default React.memo(Navigation);

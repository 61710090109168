import React from 'react';
import './ResourcesPage.css';

interface Resource {
  id: number;
  title: string;
  description: string;
  type: 'blog' | 'tutorial' | 'documentation';
  link: string;
}

const resources: Resource[] = [
  {
    id: 1,
    title: "Getting Started with AI Video Creation",
    description: "Learn the basics of creating AI-powered videos with our platform.",
    type: "tutorial",
    link: "/tutorials/getting-started"
  },
  {
    id: 2,
    title: "Advanced Techniques for Video Editing",
    description: "Dive deep into advanced video editing techniques using our AI tools.",
    type: "blog",
    link: "/blog/advanced-editing-techniques"
  },
  {
    id: 3,
    title: "API Documentation",
    description: "Comprehensive documentation for integrating our AI video creation API.",
    type: "documentation",
    link: "/docs/api"
  },
  {
    id: 4,
    title: "Maximizing Engagement with AI-Generated Content",
    description: "Learn how to create engaging content that resonates with your audience.",
    type: "blog",
    link: "/blog/maximizing-engagement"
  },
  {
    id: 5,
    title: "Video Styles and Templates",
    description: "Explore our library of video styles and templates for quick creation.",
    type: "tutorial",
    link: "/tutorials/styles-and-templates"
  },
  {
    id: 6,
    title: "Troubleshooting Common Issues",
    description: "Find solutions to common problems you might encounter during video creation.",
    type: "documentation",
    link: "/docs/troubleshooting"
  }
];

const ResourcesPage: React.FC = () => {
  return (
    <div className="resources-page">
      <section className="hero">
        <div className="container">
          <h1>Resources</h1>
          <p>Explore our collection of tutorials, blog posts, and documentation to master AI-powered video creation.</p>
        </div>
      </section>

      <section className="resource-grid">
        <div className="container">
          <div className="filters">
            <button className="filter-btn active">All</button>
            <button className="filter-btn">Tutorials</button>
            <button className="filter-btn">Blog Posts</button>
            <button className="filter-btn">Documentation</button>
          </div>

          <div className="resources">
            {resources.map((resource) => (
              <div key={resource.id} className={`resource-card ${resource.type}`}>
                <h3>{resource.title}</h3>
                <p>{resource.description}</p>
                <span className="resource-type">{resource.type}</span>
                <a href={resource.link} className="btn btn-primary">Read More</a>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="newsletter">
        <div className="container">
          <h2>Stay Updated</h2>
          <p>Subscribe to our newsletter to receive the latest updates, tutorials, and tips.</p>
          <form className="newsletter-form">
            <input type="email" placeholder="Enter your email" required />
            <button type="submit" className="btn btn-primary">Subscribe</button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default ResourcesPage;

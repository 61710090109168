import { useState, useEffect, useCallback } from 'react';
import { auth } from '../firebaseConfig';
import { onAuthStateChanged, User as FirebaseUser } from "firebase/auth";
import { getFunctions, httpsCallable, HttpsCallableResult } from "firebase/functions";
import { getFirestore, doc, getDoc } from "firebase/firestore";

interface AppUser extends FirebaseUser {
  id: string;
  name: string;
}

interface Subscription {
  isSubscribed: boolean;
  plan: string;
  credits: number;
  usageCount: number;
  tier: string;
  limit: number;
}

export function useAuthSubscription() {
  const [user, setUser] = useState<AppUser | null>(null);
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const setDefaultSubscription = () => {
    setSubscription({
      isSubscribed: false,
      plan: 'Free',
      credits: 0,
      usageCount: 0,
      tier: 'Free',
      limit: 0
    });
  };

  const fetchSubscription = useCallback(async (user: FirebaseUser) => {
    try {
      const functions = getFunctions();
      const checkSubscription = httpsCallable<void, Subscription>(functions, 'checkSubscription');
      
      console.log('Calling checkSubscription function for user:', user.uid);
      console.log('User token:', await user.getIdToken());
      const result: HttpsCallableResult<Subscription> = await checkSubscription();
      console.log('Subscription function result:', result.data);

      if (result.data) {
        setSubscription(result.data);
      } else {
        console.error("Invalid subscription data:", result.data);
        setDefaultSubscription();
      }
    } catch (error) {
      console.error("Error fetching subscription:", error);
      if ((error as any).response) {
        console.error("Response data:", (error as any).response.data);
        console.error("Response status:", (error as any).response.status);
        console.error("Response headers:", (error as any).response.headers);
      }
      setError(`Error fetching subscription: ${(error as Error).message}`);
      setDefaultSubscription();
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        const appUser: AppUser = {
          ...firebaseUser,
          id: firebaseUser.uid,
          name: firebaseUser.displayName || 'Anonymous'
        };
        setUser(appUser);
        await fetchSubscription(firebaseUser);
      } else {
        setUser(null);
        setSubscription(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [fetchSubscription]);

  const signOut = useCallback(async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error("Error signing out:", error);
    }
  }, []);

  return { user, subscription, loading, error, signOut };
}

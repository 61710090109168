import React, { useState, useEffect } from 'react';
import { Select, Button, Spin, message, Card } from 'antd';
import { collection, getDocs } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { User as FirebaseUser } from 'firebase/auth';
import './AdCopyGenerator.css';
import { db, functions, auth } from '../firebaseConfig';
import { useAuthSubscription } from './useAuthSubscription';

import { doc, getDoc } from 'firebase/firestore';

const { Option } = Select;

interface Persona {
  id: string;
  demographics: string;
  psychographics: string;
  painPoints: string;
  dreamState: string;
  likelyObjections: string;
  topLikedThings: string[];
  consumerSegments: string[];
}

interface AdCopy {
  hook: string;
  problem: string;
  solution: string;
  featuresAndBenefits: string;
  goalStateAchieved: string;
  callToAction: string;
}

const AdCopyGenerator: React.FC = () => {
  const { user, subscription } = useAuthSubscription();
  const [personas, setPersonas] = useState<Persona[]>([]);
  const [selectedPersona, setSelectedPersona] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [adCopyVariations, setAdCopyVariations] = useState<AdCopy[]>([]);
  const [isPersonaSelected, setIsPersonaSelected] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      fetchPersonas(user);
    } else {
      setPersonas([]);
    }
  }, [user]);

  const fetchPersonas = async (user: FirebaseUser) => {
    try {
      const userAnalysisRef = collection(db, 'userAnalysis', user.uid, 'personas');
      const personasSnapshot = await getDocs(userAnalysisRef);
      
      const fetchedPersonas: Persona[] = personasSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      } as Persona));

      setPersonas(fetchedPersonas);
    } catch (error) {
      console.error('Error fetching personas:', error);
      message.error('Failed to fetch personas');
    }
  };

  const generateAdCopy = async () => {
    if (!user || !selectedPersona || !isPersonaSelected) {
      message.error('Unable to generate ad copy. Please check your login status and persona selection.');
      return;
    }

    setLoading(true);

    try {
      if (!subscription || !subscription.isSubscribed) {
        throw new Error('No active subscription found. Please check your account status.');
      }

      if (subscription.credits <= 0) {
        throw new Error(`Insufficient credits. You have ${subscription.credits} credits remaining.`);
      }

      const generateAdCopyFunction = httpsCallable(functions, 'generateAdCopy');
      const response = await generateAdCopyFunction({ personaId: selectedPersona });

      if (!response.data) {
        throw new Error('No data received from the server');
      }

      const data = response.data as any;

      if (!Array.isArray(data.adCopyVariations) || data.adCopyVariations.length === 0) {
        throw new Error('Invalid or empty ad copy variations received from the server');
      }

      const adCopyVariations: AdCopy[] = data.adCopyVariations;

      setAdCopyVariations(adCopyVariations);
      message.success('Ad copy generated successfully!');
    } catch (error) {
      console.error('Error generating ad copy:', error);
      if (error instanceof Error) {
        message.error(`Failed to generate ad copy: ${error.message}`);
      } else {
        message.error('An unknown error occurred while generating ad copy');
      }
    } finally {
      setLoading(false);
    }
  };

  if (!user) {
    return <div>Please log in to use the Ad Copy Generator.</div>;
  }

  return (
    <div className="acg-container">
      <h1 className="acg-title">Ad Copy Generator</h1>
      <div className="acg-persona-selection">
        <Select
          style={{ width: 300 }}
          placeholder="Select a persona"
          onChange={(value) => {
            setSelectedPersona(value);
            setIsPersonaSelected(true);
          }}
          value={selectedPersona}
        >
          {personas.map((persona) => (
            <Option key={persona.id} value={persona.id}>
              {persona.demographics.split(',')[0]}
            </Option>
          ))}
        </Select>
        <Button 
          onClick={generateAdCopy}
          disabled={!isPersonaSelected || loading || !subscription || subscription.credits <= 0}
          type="primary"
          className="acg-generate-button"
        >
          Generate Ad Copy
        </Button>
      </div>

      {loading && (
        <div className="acg-loading-section">
          <Spin className="acg-spinner" />
          <p className="acg-status-message">Generating ad copy variations...</p>
        </div>
      )}

      {adCopyVariations.length > 0 && (
        <div className="acg-results">
          <h2 className="acg-section-title">Ad Copy Variations</h2>
          {adCopyVariations.map((adCopy, index) => (
            <Card key={index} title={`Variation ${index + 1}`} className="acg-card">
              <p><strong>Hook:</strong> {adCopy.hook}</p>
              <p><strong>Problem:</strong> {adCopy.problem}</p>
              <p><strong>Solution:</strong> {adCopy.solution}</p>
              <p><strong>Features & Benefits:</strong> {adCopy.featuresAndBenefits}</p>
              <p><strong>Goal State Achieved:</strong> {adCopy.goalStateAchieved}</p>
              <p><strong>Call to Action:</strong> {adCopy.callToAction}</p>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdCopyGenerator;
import React from 'react';
import avatar1 from '../assets/avatar1.png';
import avatar2 from '../assets/avatar2.png';
import avatar3 from '../assets/avatar3.png';
import avatar4 from '../assets/avatar4.png';
import avatar5 from '../assets/avatar5.png';
import { FaRobot, FaDesktop, FaMoneyBillWave, FaRocket, FaBrain, FaChartLine, FaUserFriends, FaBolt } from 'react-icons/fa';

const HomePage: React.FC = () => {
  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.onerror = null;
    e.currentTarget.src = 'https://via.placeholder.com/50';
  };

  return (
    <div className="home-page">
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h1>Discover the secret code of viral ads with AI</h1>
          <p>Expertly craft winning ad campaigns with our suite of AI-powered tools.</p>
          <div className="cta-buttons">
            <button className="primary-button">Get started</button>
            <button className="secondary-button">Learn more</button>
          </div>
          <div className="reviews">
            <div className="review-avatars">
              <img src={avatar1} alt="User 1" onError={handleImageError} />
              <img src={avatar2} alt="User 2" onError={handleImageError} />
              <img src={avatar3} alt="User 3" onError={handleImageError} />
              <img src={avatar4} alt="User 4" onError={handleImageError} />
              <img src={avatar5} alt="User 5" onError={handleImageError} />
            </div>
            <div className="review-text">
              <div className="stars">★★★★★ 5.0</div>
              <div><i>"The Secret Weapon of Top-Performing Ads"</i></div>
            </div>
          </div>
        </div>
      </section>

      {/* Feature Section */}
      <section className="features">
        <h2>Unlock Ad Potential with AI-Driven Precision</h2>
        <div className="feature-grid">
          <div className="feature-item">
            <div className="feature-icon">
              <FaBrain className="icon" />
            </div>
            <h3>AI-Powered Ad Analysis</h3>
            <p>Decode viral content patterns</p>
          </div>
          <div className="feature-item">
            <div className="feature-icon">
              <FaChartLine className="icon" />
            </div>
            <h3>Performance Optimization</h3>
            <p>Boost engagement and conversions</p>
          </div>
          <div className="feature-item">
            <div className="feature-icon">
              <FaUserFriends className="icon" />
            </div>
            <h3>Persona-Based Targeting</h3>
            <p>Tailor ads to your ideal audience</p>
          </div>
          <div className="feature-item">
            <div className="feature-icon">
              <FaBolt className="icon" />
            </div>
            <h3>Custom Influencer Ad Generation</h3>
            <p>Create multiple variations of ads in minutes</p>
          </div>
        </div>
      </section>

      {/* Demo Section */}
      <section className="demo">
        <h2>See the Power of AI-Driven Ad Creation</h2>
        <div className="video-container">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/bQjVh8hdlSk?si=LRSjRbqtFMjMhPuA"
            title="Demo Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </section>

     

      {/* Testimonial Section */}
      <section className="testimonials">
        <h2>What Our Customers Say</h2>
        <div className="testimonial-grid">
          <div className="testimonial-item">
            <blockquote>
              <p>AdCipher transformed our marketing strategy overnight. We've seen a 300% increase in engagement and a 40% boost in conversions since implementing their AI-driven persona targeting. It's like having a crystal ball for customer insights!</p>
              <footer>
                Sarah K.
                <span> Brand Onwer</span>
              </footer>
            </blockquote>
          </div>
          <div className="testimonial-item">
            <blockquote>
              <p>I was skeptical about AI in marketing, but AdCipher proved me wrong. We've cut our ad creation time by 75% and our latest campaign, powered by AdCipher's insights, went viral in days. This tool is an absolute must-have for any serious marketer.</p>
              <footer>
                Mike R.
                <span> Marketing Manager</span>
              </footer>
            </blockquote>
          </div>
        </div>
      </section>

      {/* CTA section */}
      <section className="cta">
        <h2>Ready to Crack the Viral Ad Code?</h2>
        <button className="secondary-button">Sign up today to get started</button>
      </section>
    </div>
  );
};

export default HomePage;

import React, { useState, useCallback, useRef, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, setDoc, serverTimestamp, collection, addDoc, runTransaction } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { storage, db } from '../firebaseConfig';
import { AlertTriangle, Play, Download, Trash2, Upload, Loader } from 'lucide-react';
import { User as FirebaseUser } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FirebaseError } from "firebase/app";
import './VideoCreator.css';
import useProcessQueue from './useProcessQueue';

interface User extends FirebaseUser {
  id: string;
  name: string | null;
  email: string | null;
}

interface Subscription {
  isSubscribed: boolean;
  plan: string;
  credits: number;
  tier: string;
  usageCount: number;
  limit: number;
}

interface AudioFile {
  file: File;
  progress: number;
  status: string;
  generatedVideo: string | null;
  error: string | null;
  predictionId: string | null;
}

interface VideoCreatorProps {
  user: User | null;
  subscription: Subscription | null;
  deductCredit: () => Promise<void>;
  onUpgradeClick: () => void;
}

const VideoCreator: React.FC<VideoCreatorProps> = ({ user, subscription, deductCredit, onUpgradeClick }) => {
  useEffect(() => {
    if (!user) {
      console.error("User is not authenticated");
      setGlobalError("Please sign in to use this feature.");
    }
  }, [user]);

  const [faceFile, setFaceFile] = useState<File | null>(null);
  const [audioFiles, setAudioFiles] = useState<AudioFile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [globalError, setGlobalError] = useState<string | null>(null);
  const [generationQueue, setGenerationQueue] = useState<number[]>([]);

  const processVideoGenerationRef = useRef<((audioFile: AudioFile, index: number) => Promise<void>) | null>(null);
  const handleErrorRef = useRef<((err: any, index: number) => void) | null>(null);

  const functions = getFunctions();
  const checkLipSyncStatus = httpsCallable(functions, 'checkLipSyncStatus');
  const generateLipSyncVideo = httpsCallable(functions, 'generateLipSyncVideo');

  const generateNextVideo = useCallback(async () => {
    if (generationQueue.length > 0 && !loading) {
      setLoading(true);
      const index = generationQueue[0];
      try {
        if (processVideoGenerationRef.current) {
          await processVideoGenerationRef.current(audioFiles[index], index);
        }
      } catch (error) {
        console.error('Error generating video:', error);
        setGlobalError('Failed to generate video. Please try again.');
        if (handleErrorRef.current) {
          handleErrorRef.current(error, index);
        }
      } finally {
        setLoading(false);
        setGenerationQueue((prev: number[]) => prev.slice(1));
      }
    }
  }, [generationQueue, loading, audioFiles]);

  useProcessQueue(generationQueue, loading, deductCredit, generateNextVideo, setGlobalError);

  const uploadFile = useCallback(async (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const storageRef = ref(storage, `uploads/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload progress for ${file.name}: ${progress}%`);
        },
        (error) => {
          console.error("Error uploading file:", error);
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            console.log(`File ${file.name} uploaded successfully`);
            resolve(downloadURL);
          } catch (error) {
            console.error("Error getting download URL:", error);
            reject(error);
          }
        }
      );
    });
  }, []);

  const uploadGeneratedVideo = useCallback(async (videoUrl: string, fileName: string): Promise<string> => {
    console.log("Uploading generated video to Firebase Storage...");

    // Fetch the video file
    const response = await fetch(videoUrl);
    const blob = await response.blob();

    // Create a reference to the location where we want to upload the file
    const storageRef = ref(storage, `generatedVideos/${user?.id}/${fileName}`);

    // Upload the file
    const uploadTask = uploadBytesResumable(storageRef, blob);

    return new Promise((resolve, reject) => {
      uploadTask.on('state_changed',
        (snapshot) => {
          // You can use this to show upload progress if desired
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          // Handle unsuccessful uploads
          console.error("Error uploading file:", error);
          reject(error);
        },
        async () => {
          // Handle successful uploads on complete
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            console.log('File available at', downloadURL);
            resolve(downloadURL);
          } catch (error) {
            console.error("Error getting download URL:", error);
            reject(error);
          }
        }
      );
    });
  }, [user]);

  const saveVideoToFirestore = useCallback(async (videoUrl: string, audioFileName: string) => {
    console.log("Attempting to save video metadata to Firestore...");
    
    if (!user) {
      console.error("No user found. Cannot save video metadata.");
      setGlobalError("User not authenticated. Please sign in again.");
      return;
    }

    try {
      await runTransaction(db, async (transaction) => {
        const videoRef = doc(collection(db, 'userVideos', user.id, 'videos'));

        const docData = {
          videoUrl,
          audioFileName,
          createdAt: serverTimestamp(),
        };
        console.log("Preparing to save document:", docData);

        transaction.set(videoRef, docData);
      });
      console.log("Video metadata saved to Firestore successfully.");
    } catch (error) {
      console.error("Error saving video metadata to Firestore:", error);
      if (error instanceof FirebaseError) {
        console.error("Firebase error code:", error.code);
        console.error("Firebase error message:", error.message);
        
        switch(error.code) {
          case 'permission-denied':
            setGlobalError("Permission denied. Please check your account permissions.");
            break;
          case 'unavailable':
            setGlobalError("Firestore is currently unavailable. Please try again later.");
            break;
          default:
            setGlobalError(`Failed to save video metadata: ${error.message}`);
        }
      } else if (error instanceof Error) {
        setGlobalError(`An unexpected error occurred: ${error.message}`);
      } else {
        setGlobalError("An unknown error occurred while saving the video metadata.");
      }
    }
  }, [user]);

  const handleError = useCallback((err: any, index: number) => {
    console.error("Full error object:", err);
    let errorMessage = 'An unknown error occurred';
    if (err instanceof Error) {
      errorMessage = `An error occurred: ${err.message}`;
    }
    setAudioFiles(prev => prev.map((af, i) => 
      i === index ? { ...af, error: errorMessage, status: 'Error occurred during video creation.' } : af
    ));
  }, []);

  handleErrorRef.current = handleError;

  const pollVideoStatus = useCallback(async (predictionId: string, index: number) => {
    console.log(`Polling video status for prediction ID: ${predictionId}, index: ${index}`);
    const pollInterval = setInterval(async () => {
      try {
        const result = await checkLipSyncStatus({ predictionId });
        const response = result.data as { status: string; output: string };
        console.log(`Poll result for ${predictionId}:`, response);

        if (response.status === 'succeeded') {
          clearInterval(pollInterval);
          console.log(`Video generation succeeded for index ${index}. Output:`, response.output);
          setAudioFiles(prev => prev.map((af, i) => 
            i === index ? { ...af, generatedVideo: response.output, status: 'Video generated successfully!' } : af
          ));
          await saveVideoToFirestore(response.output, audioFiles[index].file.name);
        } else if (response.status === 'failed') {
          clearInterval(pollInterval);
          console.error(`Video generation failed for index ${index}`);
          throw new Error('Video generation failed');
        } else {
          console.log(`Video generation in progress for index ${index}. Status: ${response.status}`);
          setAudioFiles(prev => prev.map((af, i) => 
            i === index ? { ...af, status: `Video generation in progress: ${response.status}` } : af
          ));
        }
      } catch (err) {
        clearInterval(pollInterval);
        console.error(`Error in pollVideoStatus for index ${index}:`, err);
        handleError(err, index);
      }
    }, 5000);
  }, [checkLipSyncStatus, saveVideoToFirestore, audioFiles, handleError]);

  const processVideoGeneration = useCallback(async (audioFile: AudioFile, index: number) => {
    try {
      if (!faceFile) {
        throw new Error('Face file is missing');
      }
  
      const faceUrl = await uploadFile(faceFile);
      const audioUrl = await uploadFile(audioFile.file);
  
      setAudioFiles(prev => prev.map((af, i) => 
        i === index ? { ...af, status: 'Initiating video generation...' } : af
      ));
  
      const result = await generateLipSyncVideo({ faceUrl, audioUrl });
      const response = result.data as { predictionId: string };
  
      if (!response.predictionId) {
        throw new Error('API did not return a prediction ID');
      }
  
      setAudioFiles(prev => prev.map((af, i) => 
        i === index ? { ...af, predictionId: response.predictionId, status: 'Video generation initiated. Waiting for completion...' } : af
      ));
  
      pollVideoStatus(response.predictionId, index);
  
    } catch (err) {
      handleError(err, index);
    }
  }, [faceFile, uploadFile, generateLipSyncVideo, pollVideoStatus, handleError]);

  processVideoGenerationRef.current = processVideoGeneration;

  const generateLipSync = useCallback((index: number) => {
    if (!user) {
      setGlobalError('Please sign in to generate videos.');
      return;
    }

    if (!subscription) {
      setGlobalError('Please subscribe to generate videos.');
      return;
    }

    if (subscription.credits <= 0) {
      setGlobalError('You have no credits left. Please upgrade your subscription.');
      return;
    }

    if (!faceFile) {
      setGlobalError('Please upload a face file.');
      return;
    }

    setAudioFiles(prev => prev.map((af, i) => 
      i === index ? { ...af, status: 'Queued for generation...', error: null } : af
    ));

    setGenerationQueue(prev => [...prev, index]);
  }, [user, subscription, faceFile]);

  const addAudioFile = useCallback((file: File) => {
    if (audioFiles.length < 10) {
      setAudioFiles(prev => [...prev, { 
        file, 
        progress: 0, 
        status: 'Ready to generate', 
        generatedVideo: null, 
        error: null, 
        predictionId: null 
      }]);
    } else {
      setGlobalError('You can only add up to 10 audio files.');
    }
  }, [audioFiles.length]);

  const removeAudioFile = useCallback((index: number) => {
    setAudioFiles(prev => prev.filter((_, i) => i !== index));
    setGenerationQueue(prev => prev.filter(i => i !== index).map(i => i > index ? i - 1 : i));
  }, []);

  return (
    <div className="vc-video-creator">
      <h1 className="vc-title">Create Your <i>Winning</i> Influencer Ads</h1>

      {globalError && (
        <div className="vc-error-message" role="alert">
          <AlertTriangle className="vc-icon" />
          <span>{globalError}</span>
        </div>
      )}

      {user ? (
        <>
          <div className="vc-subscription-details">
            <h2 className="vc-subtitle">Subscription Details</h2>
            <p>Plan: <span className="vc-highlight">{subscription?.plan || 'Free'}</span></p>
            <p>Credits remaining: <span className="vc-highlight">{subscription?.credits || 0}</span></p>
            <p>Videos created: <span className="vc-highlight">{subscription?.usageCount || 0}</span></p>
            {subscription?.credits === 0 && (
              <p>
                <button onClick={onUpgradeClick} className="vc-upgrade-link">
                  Upgrade your plan to get more credits
                </button>
              </p>
            )}
          </div>

          <div className="vc-file-input-section">
            <label htmlFor="face-upload" className="vc-input-label">Face File (Video or Image):</label>
            <div className="vc-file-input-wrapper">
              <input
                id="face-upload"
                type="file"
                accept="video/*,image/*"
                onChange={(e) => setFaceFile(e.target.files?.[0] || null)}
                className="vc-file-input"
              />
              <label htmlFor="face-upload" className="vc-file-input-label">
                <Upload className="vc-icon" />
                {faceFile ? faceFile.name : 'Choose a file'}
              </label>
            </div>
          </div>

          <div className="vc-file-input-section">
            <label htmlFor="audio-upload" className="vc-input-label">Audio Files (up to 10):</label>
            <div className="vc-file-input-wrapper">
              <input
                id="audio-upload"
                type="file"
                accept="audio/*"
                onChange={(e) => e.target.files && addAudioFile(e.target.files[0])}
                className="vc-file-input"
              />
              <label htmlFor="audio-upload" className="vc-file-input-label">
                <Upload className="vc-icon" />
                Choose audio file
              </label>
            </div>
          </div>

          <div className="vc-audio-file-list">
            {audioFiles.map((audioFile, index) => (
              <div key={index} className="vc-audio-file-item">
                <div className="vc-audio-file-header">
                  <span className="vc-audio-file-name">{audioFile.file.name}</span>
                  <button onClick={() => removeAudioFile(index)} className="vc-remove-button">
                    <Trash2 size={20} />
                  </button>
                </div>
                <div className="vc-status-progress">
                  <div 
                    className="vc-status-bar" 
                    style={{ width: `${audioFile.progress}%` }}
                  ></div>
                </div>
                <div className={`vc-status-text ${
                  audioFile.status.includes('successfully') 
                    ? 'vc-status-success' 
                    : audioFile.error 
                      ? 'vc-status-error' 
                      : ''
                }`}>
                  {audioFile.status}
                </div>
                {audioFile.error && (
                  <div className="vc-status-text vc-status-error">{audioFile.error}</div>
                )}
                {!audioFile.generatedVideo && (
                  <div>
                    <button
                      onClick={() => generateLipSync(index)}
                      disabled={loading || (subscription?.credits || 0) <= 0 || generationQueue.includes(index)}
                      className={`vc-generate-button ${loading || (subscription?.credits || 0) <= 0 || generationQueue.includes(index) ? 'vc-disabled' : ''}`}
                    >
                      {loading && generationQueue[0] === index ? (
                        <Loader className="vc-icon vc-spinning" />
                      ) : (
                        <Play className="vc-icon" />
                      )}
                      Generate Video (1 credit)
                    </button>
                    {(subscription?.credits || 0) <= 0 && (
                      <p className="vc-upgrade-message">
                        <button onClick={onUpgradeClick} className="vc-upgrade-link">
                          Upgrade your plan to get more credits
                        </button>
                      </p>
                    )}
                  </div>
                )}
                {audioFile.generatedVideo && (
                  <div className="vc-generated-video">
                    <video controls src={audioFile.generatedVideo} className="vc-video" />
                    <a
                      href={audioFile.generatedVideo}
                      download={`generated-video-${index + 1}.mp4`}
                      className="vc-download-button"
                    >
                      <Download className="vc-icon" />
                      Download Video
                    </a>
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="vc-sign-in-message">
          <p>Please sign in to create lip-sync videos.</p>
          {/* Add a sign-in button or link here */}
        </div>
      )}
    </div>
  );
};

export default VideoCreator;
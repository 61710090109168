import React, { useState, useEffect } from 'react';
import { Upload, Button, Table, Spin, message, Select } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import { getFunctions, httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { getFirestore, doc, setDoc, collection, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './CustomerAnalysis.css';
import { db, functions } from '../firebaseConfig';

interface CustomerPersona {
  demographics: string;
  psychographics: string;
  painPoints: string;
  dreamState: string;
  likelyObjections: string;
}

interface MarketingAngle {
  name: string;
  painPointOrDesire: string;
  dreamState: string;
  keyFeaturesBenefits: string;
  additionalInfo: string;
}

interface SaveAnalysisData {
  selectedPersona: CustomerPersona;
  marketingAngles: MarketingAngle[];
  topLikedThings: string[];
  consumerSegments: string[];
  personaUrl: string;
  anglesUrl: string;
}

interface AnalysisResult {
  customerPersonas: CustomerPersona[];
  selectedPersona?: CustomerPersona;
  topLikedThings?: string[];
  consumerSegments?: string[];
  marketingAngles?: MarketingAngle[];
}

const { Option } = Select;

const CustomerAnalysis: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [result, setResult] = useState<AnalysisResult | null>(null);
  const [uploadStatus, setUploadStatus] = useState<string>('');
  const [analysisStatus, setAnalysisStatus] = useState<string>('');
  const [selectedPersona, setSelectedPersona] = useState<string | null>(null);

  useEffect(() => {
    console.log('Component mounted');
  }, []);

  const handleFileUpload = (info: any) => {
    const { status, originFileObj } = info.file;
    if (status !== 'uploading') {
      console.log('File upload info:', info.file);
    }
    if (status === 'done') {
      setFile(originFileObj);
      setUploadStatus(`File "${info.file.name}" uploaded successfully. Ready for analysis.`);
      message.success(`${info.file.name} file uploaded successfully.`);
      console.log('File uploaded successfully:', originFileObj);
    } else if (status === 'error') {
      setUploadStatus('');
      message.error(`${info.file.name} file upload failed.`);
      console.error('File upload failed:', info.file);
    }
  };

  const parseCSV = async (file: File): Promise<string[]> => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        complete: (results) => {
          if (results.errors.length > 0) {
            console.warn('CSV parsing warnings:', results.errors);
          }
          // Assuming the CSV has headers, we'll skip the first row
          const data = results.data.slice(1) as string[][];
          // Join all fields of each row, then join all rows with a newline
          const parsedData = data.map(row => row.join(' ')).join('\n');
          console.log(`CSV parsing complete. ${data.length} rows parsed.`);
          resolve([parsedData]);
        },
        error: (error: Error) => {
          console.error('CSV parsing error:', error);
          reject(error);
        },
        skipEmptyLines: true,
      });
    });
  };

  const generateCustomerPersonas = async () => {
    if (!file) {
      message.error('Please upload a file first.');
      return;
    }

    setLoading(true);
    setAnalysisStatus('Starting customer persona analysis...');

    try {
      const reviewData = await parseCSV(file);
      
      if (reviewData.length === 0) {
        throw new Error('No data found in the file');
      }

      const prompt = `
        Here are customer reviews for an e-commerce product:

        ${reviewData[0]}

        Based on these reviews, please create 5 customer personas most likely to buy this product. Include their demographics, psychographics, pain points and struggles, dream state of using the product, and likely objections this persona may have before buying.
        
        Please format your response as a JSON object with the following structure:
        {
          "customerPersonas": [
            {
              "demographics": "",
              "psychographics": "",
              "painPoints": "",
              "dreamState": "",
              "likelyObjections": ""
            }
          ]
        }
      `;

      setAnalysisStatus('Generating customer personas...');
      const functions = getFunctions();
      const analyzeWithClaudeAI = httpsCallable(functions, 'analyzeWithClaudeAI');
      const response: HttpsCallableResult = await analyzeWithClaudeAI({
        messages: [{ role: "user", content: prompt }]
      });

      const data = response.data as any;
      if (data.error) {
        throw new Error(data.error);
      }

      const content = data.content[0].text;
      const jsonContent = content.slice(content.indexOf('{'), content.lastIndexOf('}') + 1);
      const analysisResult: AnalysisResult = JSON.parse(jsonContent);

      setResult(analysisResult);
      setAnalysisStatus('Customer personas generated. Select a persona for further analysis.');
    } catch (error) {
      console.error('Error during analysis:', error);
      setAnalysisStatus('');
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const generatePersonaDetails = async () => {
    if (!selectedPersona || !result) {
      message.error('Please select a customer persona first.');
      return;
    }

    setLoading(true);
    setAnalysisStatus('Generating details for selected persona...');

    try {
      const selectedPersonaData = result.customerPersonas[parseInt(selectedPersona)];
      const prompt = `
        Based on this customer persona:
        ${JSON.stringify(selectedPersonaData)}

        Please provide the following information:
        1. List the top 5 things this customer persona would like about the product.
        2. List the various consumer segments this persona belongs to.
        3. Brainstorm the 5 most effective marketing angles we can use to market this product to this persona with Facebook ads. Include the angle name, what pain point or desire it solves for the persona, the dream state the persona will achieve, the key features/benefits that should be highlighted in this angle, and any other relevant information.
        
        Please format your response as a JSON object with the following structure:
        {
          "topLikedThings": [],
          "consumerSegments": [],
          "marketingAngles": [
            {
              "name": "",
              "painPointOrDesire": "",
              "dreamState": "",
              "keyFeaturesBenefits": "",
              "additionalInfo": ""
            }
          ]
        }
      `;

      const functions = getFunctions();
      const analyzeWithClaudeAI = httpsCallable(functions, 'analyzeWithClaudeAI');
      const response: HttpsCallableResult = await analyzeWithClaudeAI({
        messages: [{ role: "user", content: prompt }]
      });

      const data = response.data as any;
      if (data.error) {
        throw new Error(data.error);
      }

      const content = data.content[0].text;
      const jsonContent = content.slice(content.indexOf('{'), content.lastIndexOf('}') + 1);
      const personaDetails = JSON.parse(jsonContent);

      setResult(prevResult => ({
        ...prevResult!,
        selectedPersona: selectedPersonaData,
        ...personaDetails
      }));
      setAnalysisStatus('Persona details generated.');
    } catch (error) {
      console.error('Error during persona detail generation:', error);
      setAnalysisStatus('');
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const saveToCloudStorage = async () => {
    if (!result || !result.selectedPersona || !result.marketingAngles || result.marketingAngles.length === 0) {
      console.error('No persona or marketing angles to save');
      return;
    }

    const storage = getStorage();
    const db = getFirestore();
    const functions = getFunctions();

    try {
      // Save persona
      const personaJson = JSON.stringify(result.selectedPersona);
      const personaBlob = new Blob([personaJson], { type: 'application/json' });
      const personaRef = ref(storage, `personas/${Date.now()}_persona.json`);
      await uploadBytes(personaRef, personaBlob);
      const personaUrl = await getDownloadURL(personaRef);

      // Save marketing angles
      const anglesJson = JSON.stringify(result.marketingAngles);
      const anglesBlob = new Blob([anglesJson], { type: 'application/json' });
      const anglesRef = ref(storage, `marketingAngles/${Date.now()}_angles.json`);
      await uploadBytes(anglesRef, anglesBlob);
      const anglesUrl = await getDownloadURL(anglesRef);

      // Call Cloud Function to save analysis results
      const saveAnalysisResults = httpsCallable<SaveAnalysisData, { success: boolean; message: string; personaId: string }>(functions, 'saveAnalysisResults');
      const response = await saveAnalysisResults({
        selectedPersona: result.selectedPersona,
        marketingAngles: result.marketingAngles,
        topLikedThings: result.topLikedThings || [],
        consumerSegments: result.consumerSegments || [],
        personaUrl,
        anglesUrl,
      });

      console.log('Analysis results saved:', response.data);
      // You can store the returned personaId if needed for future reference
      const savedPersonaId = response.data.personaId;
      // Handle success (e.g., show a success message to the user)
    } catch (error) {
      console.error('Error saving analysis results:', error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setLoading(false);
    }
  };

  const handleError = (error: unknown) => {
    if (error instanceof Error) {
      message.error(`An error occurred: ${error.message}`);
    } else {
      message.error('An unknown error occurred.');
    }
  };

  return (
    <div className="ca-container">
      <h1 className="ca-title">Customer Analysis Tool</h1>
      <div className="ca-upload-section">
        <Upload
          accept=".csv,.xlsx,.json"
          beforeUpload={(file) => {
            const isSupportedFormat = /\.(csv|xlsx|json)$/i.test(file.name);
            const isLessThan2MB = file.size / 1024 / 1024 < 2;
            if (!isSupportedFormat) {
              message.error(`${file.name} is not a supported file format`);
            }
            if (!isLessThan2MB) {
              message.error('File must be smaller than 2MB!');
            }
            return isSupportedFormat && isLessThan2MB;
          }}
          onChange={handleFileUpload}
          customRequest={({ file, onSuccess }) => {
            setTimeout(() => {
              onSuccess?.("ok");
            }, 0);
          }}
        >
          <Button icon={<UploadOutlined />} className="ca-upload-button">Select Customer Review File</Button>
        </Upload>
        <Button 
          onClick={generateCustomerPersonas}
          disabled={!file || loading}
          type="primary"
          className="ca-analyze-button"
        >
          Generate Customer Personas
        </Button>
      </div>

      {uploadStatus && <p className="ca-status-message">{uploadStatus}</p>}
      {loading && (
        <div className="ca-loading-section">
          <Spin className="ca-spinner" />
          <p className="ca-status-message">{analysisStatus}</p>
        </div>
      )}

      {result && result.customerPersonas && (
        <div className="ca-results">
          <h2 className="ca-section-title">Customer Personas</h2>
          <Table 
            dataSource={result.customerPersonas.map((persona, index) => ({ ...persona, key: index }))}
            columns={[
              { title: 'Demographics', dataIndex: 'demographics', key: 'demographics' },
              { title: 'Psychographics', dataIndex: 'psychographics', key: 'psychographics' },
              { title: 'Pain Points', dataIndex: 'painPoints', key: 'painPoints' },
              { title: 'Dream State', dataIndex: 'dreamState', key: 'dreamState' },
              { title: 'Likely Objections', dataIndex: 'likelyObjections', key: 'likelyObjections' },
            ]}
            className="ca-table"
          />

          <div className="ca-persona-selection">
            <Select
              style={{ width: 300 }}
              placeholder="Select a persona for further analysis"
              onChange={(value) => setSelectedPersona(value)}
            >
              {result.customerPersonas.map((persona, index) => (
                <Option key={index} value={index.toString()}>
                  Persona {index + 1}: {persona.demographics.split(',')[0]}
                </Option>
              ))}
            </Select>
            <Button 
              onClick={generatePersonaDetails}
              disabled={!selectedPersona || loading}
              type="primary"
              className="ca-analyze-button"
            >
              Analyze Selected Persona
            </Button>
          </div>

          {result.selectedPersona && (
            <>
              <h3 className="ca-subsection-title">Top Liked Things</h3>
              <ul className="ca-list">
                {result.topLikedThings?.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>

              <h3 className="ca-subsection-title">Consumer Segments</h3>
              <ul className="ca-list">
                {result.consumerSegments?.map((segment, index) => (
                  <li key={index}>{segment}</li>
                ))}
              </ul>

              <h3 className="ca-subsection-title">Marketing Angles</h3>
              <Table 
                dataSource={result.marketingAngles}
                columns={[
                  { title: 'Name', dataIndex: 'name', key: 'name' },
                  { title: 'Pain Point/Desire', dataIndex: 'painPointOrDesire', key: 'painPointOrDesire' },
                  { title: 'Dream State', dataIndex: 'dreamState', key: 'dreamState' },
                  { title: 'Key Features/Benefits', dataIndex: 'keyFeaturesBenefits', key: 'keyFeaturesBenefits' },
                  { title: 'Additional Info', dataIndex: 'additionalInfo', key: 'additionalInfo' },
                ]}
                className="ca-table"
              />

              <Button 
                onClick={saveToCloudStorage}
                disabled={loading}
                type="primary"
                className="ca-save-button"
              >
                Save Results to Cloud Storage
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomerAnalysis;
import React from 'react';
import './Features.css';
import { Video, MessageSquare, Zap, BarChart, Repeat, Shield } from 'lucide-react';

interface Feature {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const features: Feature[] = [
  {
    icon: <Video size={24} />,
    title: "AI-Powered Video Creation",
    description: "Transform a single video into multiple variations with our advanced AI technology."
  },
  {
    icon: <MessageSquare size={24} />,
    title: "Smart Ad Copy Generation",
    description: "Create compelling ad copy tailored to your target audience using AI-driven insights."
  },
  {
    icon: <Zap size={24} />,
    title: "Rapid A/B Testing",
    description: "Quickly test different ad variations to identify the most effective messaging and visuals."
  },
  {
    icon: <BarChart size={24} />,
    title: "AI-Driven Performance Optimization",
    description: "Leverage machine learning algorithms to automatically optimize ad performance and maximize ROI."
  },
  {
    icon: <Repeat size={24} />,
    title: "Personalized Ad Scaling",
    description: "Automatically scale successful ads with AI-generated variations tailored to different audience segments."
  },
  {
    icon: <Shield size={24} />,
    title: "Collaborative Asset Management",
    description: "Centralize your ad assets with version control and team collaboration features for streamlined workflows."
  }
];

const Features: React.FC = () => {
  return (
    <section className="ft-features">
      <div className="ft-container">
        <h2 className="ft-title">Key Features of adcipher</h2>
        <p className="ft-subtitle">
          Discover how our AI-powered platform revolutionizes ad creation and optimization.
        </p>
        <div className="ft-grid">
          {features.map((feature, index) => (
            <div key={index} className="ft-feature">
              <div className="ft-icon-wrapper">
                {feature.icon}
              </div>
              <h3 className="ft-feature-title">{feature.title}</h3>
              <p className="ft-feature-description">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
